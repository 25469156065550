<template>
  <a-modal :title="title" :visible="visible" :width="900" :zIndex="1001" @cancel="handleCancel">
    <template slot="footer">
      <a-button type="primary" @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="isSubmit" :disabled="isSubmit" @click="handleSubmit">提交</a-button>
    </template>

    <a-form-model ref="dataForm" :model="dataForm" :rules="rules" v-bind="layout">
      <div class="title">适用范围</div>
      <a-form-model-item label="选择广告主" prop="advertiserId">
        <a-select
          v-model="dataForm.advertiserId"
          placeholder="请选择广告主"
          allow-clear
          show-search
          option-filter-prop="children"
          @search="onSearchAdvertiser"
          @change="changeAdvertiserId"
        >
          <a-select-option v-for="item in advertiserList" :key="item.advertiserId">
            {{ item.advertiserName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="选择广告平台" prop="adPlatformType">
        <a-radio-group v-model="dataForm.adPlatformType">
          <a-radio :value="item.id" v-for="item in platformList" :key="item.id">{{ item.adPlatformName }}</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="选择规则层级" prop="applyObjectType" ref="">
        <a-radio-group v-model="dataForm.applyObjectType" @change="onChangeType">
          <a-radio v-for="item in ruleHierarchyData" :value="item.code" :key="item.code">{{ item.label }}</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <template v-if="dataForm.applyObjectType !== 'ACCOUNT'">
        <a-form-model-item
          v-if="!THAN_TYPE_IS_SHOW"
          :label="(ruleHierarchyData.filter((v) => v.code === dataForm.applyObjectType)[0] || {}).label"
          prop="applyObjectScopeType"
          ref="applyObjectScopeType"
        >
          <a-radio-group v-model="dataForm.applyObjectScopeType">
            <template v-if="dataForm.applyObjectType == 'AD_MATERIAL'">
              <a-radio value="VIDEO">视频</a-radio>
              <a-radio value="IMAGE">图片</a-radio>
            </template>
            <template v-else>
              <a-radio value="ALL">全部</a-radio>
            </template>
            <a-radio value="SPECIFY" v-if="ruleType !== 'ADVERTISING_REVIEW_FAILED'"
            >指定
              <a-input
                v-model="dataForm.applyObjectIdArr"
                :placeholder="`请输入${
                  (ruleHierarchyData.filter((v) => v.code === dataForm.applyObjectType)[0] || {}).label
                }ID，多个${
                  (ruleHierarchyData.filter((v) => v.code === dataForm.applyObjectType)[0] || {}).label
                }用&quot;,&quot;分隔`"
                @change="onChangeInputId"
                :disabled="dataForm.applyObjectScopeType == 'ALL'|| dataForm.applyObjectScopeType == 'VIDEO' || dataForm.applyObjectScopeType == 'IMAGE' ? true : false"
              />
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </template>

      <div class="title">触发条件</div>
      <a-form-model-item label="" prop="conditionArr" v-if="ruleType === 'OVERT_THE_BUDGET'">
        <div v-for="(item, index) in dataForm.conditionArr" :key="index">
          <a-space>
            <span>日消耗≥</span>
            <a-input-number v-model="item.oneNum" :precision="0" :min="1" :max="100" class="mrl-5" />
            <span>%日预算</span>
          </a-space>
        </div>
      </a-form-model-item>
      <a-form-model-item label="" prop="conditionArr" v-if="ruleType === 'HIGE_CONSUMPTION_ZERO_CONVERSION'">
        <div v-for="(item, index) in dataForm.conditionArr" :key="index">
          <a-space>
            <span>1、累计消耗≥</span>
            <a-input-number v-model="item.oneNum" :precision="0" :min="1" :max="10000" class="mrl-5" />
            <span>目标转化为0</span>
            <span class="ml-20">2、系统当前日期消耗＞0</span>
          </a-space>
        </div>
      </a-form-model-item>
      <a-form-model-item label="" prop="conditionArr" v-if="ruleType === 'INSUFFICIENT_BALANCE'">
        <div v-for="(item, index) in dataForm.conditionArr" :key="index">
          <a-space>
            <span>1、余额＜</span>
            <a-input-number v-model="item.oneNum" :precision="0" :min="1" :max="100000" class="mrl-5" />
            <span class="ml-20">2、系统当前日期消耗＞0</span>
          </a-space>
        </div>
      </a-form-model-item>
      <a-form-model-item label="" prop="conditionArr" v-if="ruleType === 'NEW_ADVERTISING_LOW_CONSUMPTION'">
        <div v-for="(item, index) in dataForm.conditionArr" :key="index">
          <a-space>
            <span>广告创建≥</span>
            <a-input-number v-model="item.oneNum" :precision="0" :min="1" :max="1000" class="mrl-5" />
            <span>小时且≤48小时，消耗＜</span>
            <a-input-number v-model="item.twoNum" :precision="0" :min="1" :max="1000" class="mrl-5" />
          </a-space>
        </div>
      </a-form-model-item>
      <a-form-model-item label="" prop="conditionArr" v-if="ruleType === 'ADVERTISING_REVIEW_FAILED'">
        状态为审核不通过且对应广告更新时间在24小时内
      </a-form-model-item>
      <a-form-model-item
        label=""
        prop="conditionArr"
        v-if="ruleType === 'LOW_CLICK_RATE'"
        :wrapper-col="{ span: 24, offset: 0 }"
      >
        <div v-for="(item, index) in dataForm.conditionArr" :key="index">
          <a-space>
            <a-radio-group v-model="dataForm.condition.select">
              <a-radio :value="item.select"></a-radio>
            </a-radio-group>
            <span>1、过去 </span>
            <a-input-number v-model="item.oneNum" :precision="0" :min="1" :max="30" class="mrl-5" />
            <span>天，{{ index == 0 ? '消耗' : '展示量' }}≥</span>
            <a-input-number v-model="item.twoNum" :precision="0" :min="1" :max="item.max" class="mrl-5" />
            <span> ，点击率＜</span>
            <a-input-number v-model="item.threeNum" :precision="2" :min="0.01" :max="100" class="mrl-5" />
            <span>%</span>
            <span class="ml-20">2、系统当前日期消耗＞0</span>
          </a-space>
        </div>
      </a-form-model-item>
      <a-form-model-item
        label=""
        prop="conditionArr"
        v-if="ruleType === 'LOW_CLICK_CONVERSION_RATE'"
        :wrapper-col="{ span: 24, offset: 0 }"
      >
        <div v-for="(item, index) in dataForm.conditionArr" :key="index">
          <a-space>
            <a-radio-group v-model="dataForm.condition.select">
              <a-radio :value="item.select"></a-radio>
            </a-radio-group>
            <span>1、过去 </span>
            <a-input-number v-model="item.oneNum" :precision="0" :min="1" :max="30" class="mrl-5" />
            <span>天，{{ index == 0 ? '消耗' : '点击数' }}≥</span>
            <a-input-number v-model="item.twoNum" :precision="0" :min="1" :max="item.max" class="mrl-5" />
            <span> ，点击转化率＜</span>
            <a-input-number v-model="item.threeNum" :precision="2" :min="0.01" :max="100" class="mrl-5" />
            <span>%</span>
            <span class="ml-20">2、系统当前日期消耗＞0</span>
          </a-space>
        </div>
      </a-form-model-item>
      <a-form-model-item
        label=""
        prop="conditionArr"
        v-if="ruleType === 'HIGH_CONVERSION_CONST'"
        :wrapper-col="{ span: 24, offset: 0 }"
      >
        <div v-for="(item, index) in dataForm.conditionArr" :key="index">
          <a-space v-if="index == 0">
            <a-radio-group v-model="dataForm.condition.select">
              <a-radio :value="item.select"></a-radio>
            </a-radio-group>
            <span>系统当前日期，转化成本≥</span>
            <a-input-number v-model="item.oneNum" :precision="0" :min="0.01" :max="item.max" class="mrl-5" />
            <span class="ml-20">2、转化数≥2</span>
          </a-space>
          <a-space v-else>
            <a-radio-group v-model="dataForm.condition.select">
              <a-radio :value="item.select"></a-radio>
            </a-radio-group>
            <span>1、过去 </span>
            <a-input-number v-model="item.oneNum" :precision="0" :min="1" :max="30" class="mrl-5" />
            <span>天，转化成本≥</span>
            <a-input-number v-model="item.twoNum" :precision="0" :min="0.01" :max="item.max" class="mrl-5" />
            <span class="ml-20">2、转化数≥2</span>
            <span class="ml-20">3、系统当前日期消耗＞0</span>
          </a-space>
        </div>
      </a-form-model-item>
      <a-form-model-item
        label=""
        prop="conditionArr"
        v-if="ruleType === 'ZERO_CONVERSION'"
        :wrapper-col="{ span: 24, offset: 0 }"
      >
        <div v-for="(item, index) in dataForm.conditionArr" :key="index">
          <a-space>
            <a-radio-group v-model="dataForm.condition.select">
              <a-radio :value="item.select"></a-radio>
            </a-radio-group>
            <span>1、过去 </span>
            <a-input-number v-model="item.oneNum" :precision="0" :min="1" :max="30" class="mrl-5" />
            <span>天，目标转化为0</span>
            <span class="ml-20">2、系统当前日期消耗＞0</span>
            <span class="ml-20">3、统计周期有消耗的天数≥统计周期天数x50%</span>
          </a-space>
        </div>
      </a-form-model-item>
      <a-form-model-item
        label=""
        prop="conditionArr"
        v-if="ruleType === 'LOW_CONVERSION_RATE_OF_MESSAGE_CLUE'"
        :wrapper-col="{ span: 24, offset: 0 }"
      >
        <div v-for="(item, index) in dataForm.conditionArr" :key="index">
          <a-space v-if="index == 0">
            <a-radio-group v-model="dataForm.condition.select">
              <a-radio :value="item.select"></a-radio>
            </a-radio-group>
            <span>1、系统当前日期，私信线索转化率≤</span>
            <a-input-number v-model="item.oneNum" :precision="0" :min="1" :max="item.max" class="mrl-5" />
            %
            <span class="ml-20">2、私信开口数≥10</span>
          </a-space>
          <a-space v-else>
            <a-radio-group v-model="dataForm.condition.select">
              <a-radio :value="item.select"></a-radio>
            </a-radio-group>
            <span>2、过去 </span>
            <a-input-number v-model="item.oneNum" :precision="0" :min="1" :max="30" class="mrl-5" />
            <span>天，私信线索转化率≤</span>
            <a-input-number v-model="item.twoNum" :precision="0" :min="1" :max="item.max" class="mrl-5" />
            %
            <span class="ml-20">2、私信开口数≥20</span>
            <span class="ml-20">3、系统当前日期消耗＞0</span>
          </a-space>
        </div>
      </a-form-model-item>
      <a-form-model-item
        label=""
        prop="conditionArr"
        v-if="ruleType === 'HIGH_COST_OF_CLUE_MESSAGE_COUNT'"
        :wrapper-col="{ span: 24, offset: 0 }"
      >
        <div v-for="(item, index) in dataForm.conditionArr" :key="index">
          <a-space v-if="index == 0">
            <a-radio-group v-model="dataForm.condition.select">
              <a-radio :value="item.select"></a-radio>
            </a-radio-group>
            <span>1、系统当前日期，私信留资成本≥</span>
            <a-input-number v-model="item.oneNum" :precision="0" :min="1" :max="item.max" class="mrl-5" />
            <span class="ml-20">2、私信留资数≥2</span>
          </a-space>
          <a-space v-else>
            <a-radio-group v-model="dataForm.condition.select">
              <a-radio :value="item.select"></a-radio>
            </a-radio-group>
            <span>2、过去 </span>
            <a-input-number v-model="item.oneNum" :precision="0" :min="1" :max="30" class="mrl-5" />
            <span>天，私信留资成本≥</span>
            <a-input-number v-model="item.twoNum" :precision="0" :min="1" :max="item.max" class="mrl-5" />
            <span class="ml-20">2、私信留资数≥2</span>
            <span class="ml-20">3、系统当前日期消耗＞0</span>
          </a-space>
        </div>
      </a-form-model-item>
      <a-form-model-item
        label=""
        prop="conditionArr"
        v-if="ruleType === 'HIGH_COST_OF_MESSAGE_ACTION'"
        :wrapper-col="{ span: 24, offset: 0 }"
      >
        <div v-for="(item, index) in dataForm.conditionArr" :key="index">
          <a-space v-if="index == 0">
            <a-radio-group v-model="dataForm.condition.select">
              <a-radio :value="item.select"></a-radio>
            </a-radio-group>
            <span>1、系统当前日期，私信开口成本≥</span>
            <a-input-number v-model="item.oneNum" :precision="0" :min="1" :max="item.max" class="mrl-5" />
            <span class="ml-20">2、私信开口数≥2</span>
          </a-space>
          <a-space v-else>
            <a-radio-group v-model="dataForm.condition.select">
              <a-radio :value="item.select"></a-radio>
            </a-radio-group>
            <span>2、过去 </span>
            <a-input-number v-model="item.oneNum" :precision="0" :min="1" :max="30" class="mrl-5" />
            <span>天，私信开口成本≥</span>
            <a-input-number v-model="item.twoNum" :precision="0" :min="1" :max="item.max" class="mrl-5" />
            <span class="ml-20">2、私信开口数≥2</span>
            <span class="ml-20">3、系统当前日期消耗＞0</span>
          </a-space>
        </div>
      </a-form-model-item>
      <a-form-model-item
        v-if="THAN_TYPE_IS_SHOW"
      >
        <div class="VIDEO_LESS_THAN_text-box" v-if="ruleType == 'PROJECT_LESS_THAN'">
          <p> 1、系统日期前7天，同一个产品（车系），消耗＞0的项目数＜2</p>
          <p> 2、项目营销场景为短视频/图片</p>
          <p> 3、项目优化目标为私信消息/私信留资/表单提交</p>
          <p> 4、系统当前日期平台账套消耗＞0</p>
        </div>
        <div class="VIDEO_LESS_THAN_text-box" v-else>
          <p> 1、广告内消耗＞0的{{ ruleType == 'VIDEO_LESS_THAN' ? '视频' : ruleType == 'TITLE_LESS_THAN' ? '标题' : ''
            }}数＜{{ ruleType == 'VIDEO_LESS_THAN' ? '3' : ruleType == 'TITLE_LESS_THAN' ? '4' : '' }}</p>
          <p> 2、广告关联的项目营销是场景为短视频/图片</p>
          <p> 3、广告关联的项目优化目标为私信消息/私信留资/表单提交任意一种</p>
          <p> 4、系统当前日期广告的消耗＞0</p>
        </div>
      </a-form-model-item>

      <div class="title">执行</div>
      <a-form-model-item
        label="企业微信通知"
        :extra="ruleType === 'ADVERTISING_REVIEW_FAILED' ? '' : '相同内容每天通知提醒1次'">
        <a-select
          mode="multiple"
          v-model="dataForm.receiverIdList"
          placeholder="请选择"
          allow-clear
          show-search
          option-filter-prop="children"
          @change="onChangeCommander"
        >
          <a-select-option v-for="item in commanderIdList" :key="item.userid" :name="item.employeeName">
            {{ item.employeeName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        label=""
        extra="预算每次最少提高100元"
        v-if="ruleType === 'OVERT_THE_BUDGET'"
        prop="actionParamObj"
      >
        <a-space>
          <a-checkbox v-model="dataForm.actionParamObj.checkbox">提高预算</a-checkbox>
          <a-radio-group v-model="dataForm.actionParamObj.select">
            <a-radio :value="1"
            >
              <a-input-number v-model="dataForm.actionParamObj.oneNum" :precision="0" :min="1" :max="100" />
              %
            </a-radio
            >
            <a-radio :value="2"
            >
              <a-input-number v-model="dataForm.actionParamObj.twoNum" :precision="0" :min="100" :max="100000" />
              元
            </a-radio
            >
          </a-radio-group>
        </a-space>
      </a-form-model-item>
      <a-form-model-item
        label=""
        v-if="ruleType === 'HIGE_CONSUMPTION_ZERO_CONVERSION'
        || ruleType === 'LOW_CLICK_RATE'
        || ruleType == 'LOW_CLICK_CONVERSION_RATE'
        || ruleType === 'ZERO_CONVERSION'
        || ruleType == 'LOW_CONVERSION_RATE_OF_MESSAGE_CLUE'
        || ruleType == 'HIGH_COST_OF_CLUE_MESSAGE_COUNT'
        || ruleType == 'HIGH_COST_OF_MESSAGE_ACTION'
        || ruleType == 'HIGH_CONVERSION_CONST'
          ">
        <a-checkbox v-model="dataForm.actionParamObj.checkbox">暂停投放</a-checkbox>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { isEmpty, pick } from 'lodash-es';

const ruleHierarchyList = [
  { code: 'AD_PROJECT', label: '巨量项目' },
  { code: 'AD_PROMOTION', label: '广告' },
  { code: 'AD_MATERIAL', label: '素材' },
];

const baseForm = {
  advertiserId: undefined,
  adPlatformType: '1',
  applyObjectType: undefined,
  applyObjectScopeType: undefined,
  applyObjectIdArr: '',
  condition: { select: 0 },
  conditionArr: [],
  receiverIdList: [],
  actionParamObj: {},
};

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    ruleType: {
      type: String,
      default: '',
    },
    commanderIdList: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    visible: {
      handler(val) {
        this.dataForm.applyObjectType = 'AD_PROJECT';
        this.dataForm.applyObjectScopeType = 'ALL';
        this.receiverList = [];
        this.THAN_TYPE_IS_SHOW = false
        this.dataForm.advertiserId = sessionStorage.getItem('__advertiserId__');

        switch (this.ruleType) {
          case 'OVERT_THE_BUDGET': //日预算撞线
            this.ruleHierarchyData = [
              { code: 'ACCOUNT', label: '平台账套' },
              { code: 'AD_PROJECT', label: '巨量项目' },
              { code: 'AD_PROMOTION', label: '广告' },
            ];
            this.dataForm.conditionArr = [
              {
                select: 0,
                oneNum: 90,
              },
            ];
            this.dataForm.actionParamObj = {
              actionName: '提高预算',
              actionType: 'INCRESE_BUDGET',
              select: 1,
              checkbox: false,
              oneNum: 10,
              oneFormat: 'percentage',
              twoNum: 100,
              twoFormat: 'number',
            };
            this.dataForm.applyObjectType = 'ACCOUNT';
            break;

          case 'HIGE_CONSUMPTION_ZERO_CONVERSION': //高消耗0转化
            this.ruleHierarchyData = ruleHierarchyList;
            this.dataForm.conditionArr = [
              {
                select: 0,
                oneNum: 300,
              },
            ];
            this.dataForm.actionParamObj = {
              actionName: '暂停投放',
              actionType: 'STOP_PROMOTION',
              checkbox: false,
            };
            break;

          case 'INSUFFICIENT_BALANCE': //余额不足
            this.ruleHierarchyData = [{ code: 'ACCOUNT', label: '平台账套' }];
            this.dataForm.conditionArr = [
              {
                select: 0,
                oneNum: 1000,
              },
            ];
            this.dataForm.applyObjectType = 'ACCOUNT';
            break;

          case 'NEW_ADVERTISING_LOW_CONSUMPTION': //新建广告消耗低
            this.ruleHierarchyData = [{ code: 'AD_PROMOTION', label: '广告' }];
            this.dataForm.conditionArr = [
              {
                select: 0,
                oneNum: 3,
                twoNum: 10,
              },
            ];
            this.dataForm.applyObjectType = 'AD_PROMOTION';
            break;

          case 'ADVERTISING_REVIEW_FAILED': //广告审核不通过
            this.ruleHierarchyData = [{ code: 'AD_PROMOTION', label: '广告' }, { code: 'AD_MATERIAL', label: '素材' }];
            this.dataForm.applyObjectType = 'AD_PROMOTION';
            break;

          case 'LOW_CLICK_RATE': //新建广告低点击率
            this.ruleHierarchyData = ruleHierarchyList;
            this.dataForm.conditionArr = [
              {
                select: 0,
                oneNum: 7,
                twoNum: 100,
                threeNum: 1.0,
                max: 10000,
              },
              {
                select: 1,
                oneNum: 7,
                twoNum: 1000,
                threeNum: 1.0,
                max: 100000,
              },
            ];
            this.dataForm.actionParamObj = {
              actionName: '暂停投放',
              actionType: 'STOP_PROMOTION',
              checkbox: false,
            };
            break;

          case 'LOW_CLICK_CONVERSION_RATE': //点击转化率低
            this.ruleHierarchyData = ruleHierarchyList;
            this.dataForm.conditionArr = [
              {
                select: 0,
                oneNum: 7,
                twoNum: 100,
                threeNum: 1.0,
                max: 10000,
              },
              {
                select: 1,
                oneNum: 7,
                twoNum: 1000,
                threeNum: 1.0,
                max: 100000,
              },
            ];
            this.dataForm.actionParamObj = {
              actionName: '暂停投放',
              actionType: 'STOP_PROMOTION',
              checkbox: false,
            };
            break;

          case 'HIGH_CONVERSION_CONST': //转化成本高
            this.ruleHierarchyData = ruleHierarchyList;
            this.dataForm.conditionArr = [
              {
                select: 0,
                oneNum: 100,
                max: 10000,
              },
              {
                select: 1,
                oneNum: 7,
                twoNum: 100.0,
                max: 10000,
              },
            ];
            this.dataForm.actionParamObj = {
              actionName: '暂停投放',
              actionType: 'STOP_PROMOTION',
              checkbox: false,
            };
            break;

          case 'ZERO_CONVERSION': //长时间0转化
            this.ruleHierarchyData = ruleHierarchyList;
            this.dataForm.conditionArr = [
              {
                select: 0,
                oneNum: 7,
                max: 30,
              },
            ];
            this.dataForm.actionParamObj = {
              actionName: '暂停投放',
              actionType: 'STOP_PROMOTION',
              checkbox: false,
            };
            break;

          case 'LOW_CONVERSION_RATE_OF_MESSAGE_CLUE': //私信线索转化率低
            this.ruleHierarchyData = ruleHierarchyList;
            this.dataForm.conditionArr = [
              {
                select: 0,
                oneNum: 20,
                max: 100,
              },
              {
                select: 1,
                oneNum: 7,
                twoNum: 20,
                max: 100,
              },
            ];
            this.dataForm.actionParamObj = {
              actionName: '暂停投放',
              actionType: 'STOP_PROMOTION',
              checkbox: false,
            };
            break;

          case 'HIGH_COST_OF_CLUE_MESSAGE_COUNT': //私信留资成本高
            this.ruleHierarchyData = ruleHierarchyList;
            this.dataForm.conditionArr = [
              {
                select: 0,
                oneNum: 150,
                max: 1000,
              },
              {
                select: 1,
                oneNum: 7,
                twoNum: 150,
                max: 1000,
              },
            ];
            this.dataForm.actionParamObj = {
              actionName: '暂停投放',
              actionType: 'STOP_PROMOTION',
              checkbox: false,
            };
            break;

          case 'HIGH_COST_OF_MESSAGE_ACTION': //私信开口成本高
            this.ruleHierarchyData = ruleHierarchyList;
            this.dataForm.conditionArr = [
              {
                select: 0,
                oneNum: 150,
                max: 1000,
              },
              {
                select: 1,
                oneNum: 7,
                twoNum: 150,
                max: 1000,
              },
            ];
            this.dataForm.actionParamObj = {
              actionName: '暂停投放',
              actionType: 'STOP_PROMOTION',
              checkbox: false,
            };
            break;

          case 'VIDEO_LESS_THAN': //投放中广告视频数不足
            this.ruleHierarchyData = [{ code: 'AD_PROMOTION', label: '广告' }];
            this.dataForm.applyObjectType = 'AD_PROMOTION';
            this.THAN_TYPE_IS_SHOW = true;
            break;

          case 'TITLE_LESS_THAN': //投放中广告标题数不足
            this.ruleHierarchyData = [{ code: 'AD_PROMOTION', label: '广告' }];
            this.dataForm.applyObjectType = 'AD_PROMOTION';
            this.THAN_TYPE_IS_SHOW = true;
            break;
          case 'PROJECT_LESS_THAN': //投放中产品项目数不足
            this.ruleHierarchyData = [
              { code: 'ACCOUNT', label: '平台账套' },
            ];
            this.dataForm.applyObjectType = 'ACCOUNT';
            this.THAN_TYPE_IS_SHOW = true;
            break;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    let checkApplyObjectScopeType = (rule, value, callback) => {
      if (!value || (value == 'SPECIFY' && !this.dataForm.applyObjectIdArr)) {
        callback(new Error(`请填写ID`));
      } else {
        callback();
      }
    };

    let checkCondition = (rule, value, callback) => {
      let state = false;
      value.map((item) => {
        if (this.dataForm.condition.select === item.select) {
          this.filterItem(item).map((v) => {
            if ((item[v] ?? '') === '') {
              state = true;
            }
          });
        }
      });

      if (state) {
        callback(new Error(`请填写相关数据`));
      } else {
        callback();
      }
    };

    let checkActionParamObj = (rule, value, callback) => {
      let state = false;

      if (this.dataForm.actionParamObj.checkbox) {
        if (this.dataForm.actionParamObj.select == 1 && !this.dataForm.actionParamObj.oneNum) {
          state = true;
        }
        if (this.dataForm.actionParamObj.select == 2 && !this.dataForm.actionParamObj.twoNum) {
          state = true;
        }
      }

      if (state) {
        callback(new Error(`请填写相关数据`));
      } else {
        callback();
      }
    };

    return {
      layout: {
        labelCol: { span: 5 },
        wrapperCol: { span: 15 },
      },
      dataForm: _.cloneDeep(baseForm),
      rules: {
        advertiserId: [{ required: true, message: '请选择广告主', trigger: 'change' }],
        adPlatformType: [{ required: true, message: '请选择广告平台', trigger: 'change' }],
        applyObjectType: [{ required: true, message: '请选择规则层级', trigger: 'change' }],
        applyObjectScopeType: [{ required: true, validator: checkApplyObjectScopeType, trigger: 'change' }],
        conditionArr: [{ validator: checkCondition, trigger: 'change' }],
        receiverIdList: [{ type: 'array', required: true, message: '请添加企业微信通知人', trigger: 'change' }],
        actionParamObj: [{ validator: checkActionParamObj, trigger: 'change' }],
      },
      isSubmit: false,
      advertiserList: [],
      platformList: [
        {
          id: '1',
          adPlatformName: '巨量广告',
        },
      ],
      ruleHierarchyData: [],
      receiverList: [],
      THAN_TYPE_IS_SHOW: false,
    };
  },
  created() {
    this.getAccountList();
    // this.getAdPlatformList();
  },
  methods: {
    // onChange(){
    //   alert(111)
    //   // console.log(e)
    // },
    filterItem(obj) {
      let arr = [];
      Object.keys(obj).forEach((item) => {
        arr.push(item);
      });
      return arr;
    },
    handleCancel() {
      this.changeAdvertiserId();
      this.dataForm = _.cloneDeep(baseForm);
      this.$refs.dataForm.clearValidate();
      this.$emit('handleCancel');
    },
    handleSubmit() {
      this.$refs.dataForm.validate((valid, obj) => {
        if (valid) {
          this.isSubmit = true;
          let actionArr = [
            {
              actionName: '企微提醒',
              actionType: 'SEND_MSG',
              actionParam: {
                channel: ['WEWORK'],
                receiverList: this.receiverList,
              },
            },
          ];

          let actionParamObj = this.dataForm.actionParamObj;
          let ruleTypeArr = ['HIGE_CONSUMPTION_ZERO_CONVERSION', 'LOW_CLICK_RATE', 'LOW_CLICK_CONVERSION_RATE', 'HIGH_CONVERSION_CONST', 'ZERO_CONVERSION', 'LOW_CONVERSION_RATE_OF_MESSAGE_CLUE', 'HIGH_COST_OF_CLUE_MESSAGE_COUNT', 'HIGH_COST_OF_MESSAGE_ACTION'];
          if (actionParamObj.checkbox) {
            if (this.ruleType === 'OVERT_THE_BUDGET') {
              actionArr.push({
                actionName: actionParamObj.actionName,
                actionType: actionParamObj.actionType,
                actionParam: {
                  valueFormatList: [
                    {
                      valueFormat: actionParamObj.select == 1 ? actionParamObj.oneFormat : actionParamObj.twoFormat,
                      value: actionParamObj.select == 1 ? actionParamObj.oneNum : actionParamObj.twoNum,
                    },
                  ],
                },
              });
            }
            if (ruleTypeArr.includes(this.ruleType)) {
              actionArr.push({
                actionName: actionParamObj.actionName,
                actionType: actionParamObj.actionType,
              });
            }
          }

          let _form = this.dataForm;

          let data = {
            ruleType: this.ruleType,
            ruleMetadataId: 1,
            actionArr,
            advertiserId: _form.advertiserId,
            adPlatformType: _form.adPlatformType,
            applyObjectType: _form.applyObjectType,
            applyObjectScopeType: _form.applyObjectScopeType,
            applyObjectIdArr: _form.applyObjectType !== 'ACCOUNT' && _form.applyObjectIdArr != '' ? _form.applyObjectIdArr.split(',') : undefined,
            condition: _form.conditionArr[_form.condition.select],
          };
          this.changeAdvertiserId();
          this.$api.core.deliverManage.saveRule(data).then((res) => {
            this.isSubmit = false;
            if (res.code == 200) {
              this.$message.success(`保存成功`);
              this.handleCancel();
              this.$emit('handleSubmit');
            } else {
              this.$message.error(`保存失败，${ res.message }`);
            }
          });
        }
      });
    },
    //获取广告主列表
    getAccountList(advertiserName) {
      this.$api.core.pphtManage.getAccountList({ advertiserName: advertiserName, page: 1, size: 800 }).then((res) => {
        if (res.code == 200) {
          this.advertiserList = res.data.list;
        } else {
          this.$message.error(`获取详情失败，${ res.message }`);
        }
      });
    },
    //搜索广告主
    onSearchAdvertiser(val) {
      this.getAccountList(val);
    },
    // 选择后记录选中的广告主
    changeAdvertiserId() {
      if (this.dataForm.advertiserId) {
        sessionStorage.setItem('__advertiserId__', this.dataForm.advertiserId);
      }

    },
    // 获取广告平台
    getAdPlatformList() {
      this.$api.core.pphtManage.getAdPlatformList().then((res) => {
        if (res.code == 200) {
          this.platformList = res.data;
        } else {
          console.error(`获取平台列表失败，${ res.message }`);
        }
      });
    },
    onChangeType(val) {
      if (this.dataForm.applyObjectType == 'AD_MATERIAL') {
        this.dataForm.applyObjectScopeType = 'VIDEO';
      } else {
        this.dataForm.applyObjectScopeType = 'ALL';
      }
      if (this.dataForm.applyObjectType !== 'ACCOUNT') {
        // this.$refs.applyObjectScopeType.onFieldChange();
        this.dataForm.applyObjectIdArr = '';
      }
    },
    // onChangeScopeType(){
    //   if(this.dataForm.applyObjectScopeType == 'ALL'){
    //     this.$refs.applyObjectScopeType.onFieldChange()
    //   }
    // },
    onChangeInputId() {
      if (this.dataForm.applyObjectType !== 'ACCOUNT') {
        this.$refs.applyObjectScopeType.onFieldChange();
      }
    },
    //选择微信联系人
    onChangeCommander(val, option) {
      console.log(val, option);
      let receiverList = [];
      option.map((item) => {
        receiverList.push({
          receiverId: item.key,
          receiverName: item.data.attrs.name,
        });
      });
      this.receiverList = receiverList;
    },
  },
};
</script>

<style scoped lang="less">
.title {
  margin: 30px 0 15px;
  color: #999;
}

.mrl-5 {
  margin: 0 5px;
}

.ml-20 {
  margin-left: 20px;
}

/deep/ .ant-space {
  flex-wrap: wrap;
}

.VIDEO_LESS_THAN_text-box {
  p {
    line-height: 14px;
  }
}
</style>
