<template>
  <a-card>
    <a-page-header :title="'自动规则管理'" />
    <a-form-model ref="form" :model="form" v-bind="layout">
      <a-row :gutter="16">
        <a-col :span="8">
          <a-form-model-item label="规则类型">
            <a-select mode="multiple" v-model="form.ruleTypeList" placeholder="请选择" show-search allowClear>
              <a-select-option v-for="item in rulesTypeList" :key="item.code" :value="item.code">{{
                  item.msg
                }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="状态">
            <a-select v-model="form.status" placeholder="请选择" show-search allowClear>
              <a-select-option key="ENABLE">启用</a-select-option>
              <a-select-option key="DISABLE">暂停</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="广告主">
            <a-input v-model="form.advertiserName" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="自动规则ID">
            <a-input v-model="form.ruleId" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col span="18">
          <a-button type="primary" @click="openRulesListModal">新建规则</a-button>
        </a-col>
        <a-col span="6" class="flex-end">
          <a-button @click="handleReset">重置</a-button>
          <a-button style="margin: 0 5px 0 20px" type="primary" @click="handleSearch">查询</a-button>
        </a-col>
      </a-row>
    </a-form-model>

    <a-table
      :loading="loading"
      :columns="columns"
      :data-source="dataList"
      :row-key="(record, index) => index"
      :pagination="false"
      style="margin-top: 30px"
    >
      <div slot="action" slot-scope="text, record">
        <base-button
          :type="'link'"
          :title="record.ruleStatus == 'ENABLE' ? '暂停' : '启用'"
          @onClickBtn="updateStatus(record)"
        ></base-button>
        <base-button :type="'link'" :title="'删除'" @onClickBtn="deleteRule(record.id)"></base-button>
        <base-button :type="'link'" :title="'详情'" @onClickBtn="getRuleDetail(record.id)"></base-button>
      </div>
    </a-table>

    <base-pagination
      :currentPage="pagination.current"
      :pageSize="pagination.pageSize"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />

    <!-- 选择规则类型 -->
    <a-modal :visible="openRulesModal" :footer="null" :width="800" @cancel="closeRulesListModal">
      <template slot="title">
        选择规则类型
        <span style="color: #9d9c9c;font-size: 14px;padding-left: 8px;font-weight: 400">(红色为重要自动规则)</span>
      </template>
      <a-radio-group v-model="ruleType" button-style="solid">
        <a-radio-button
          v-for="item in rulesTypeList"
          :value="item.code"
          :key="item.code"
          @click="onCLickType(item)"
          :style="item.redMark ? { color: 'red' } : ''"
        >
          {{ item.msg }}
        </a-radio-button>
      </a-radio-group>

      <p style="padding: 20px 0 ;color: #9d9c9c">PS:
        广告审核不通过、投放中广告视频数不足，投放中广告标题数不足、投放中产品项目数不足系统自动给每个广告主创建</p>
    </a-modal>

    <!-- 新建规则 -->
    <ruleModal
      :title="rulesTitle"
      :visible="rulesVisible"
      :rule-type="activeRuleType"
      :commander-id-list="commanderIdList"
      @handleCancel="handleCancel"
      @handleSubmit="handleSubmit"
    />

    <!-- 自动规则详情 -->
    <a-modal title="自动规则详情" :visible="detailVisible" :footer="null" :width="600" @cancel="closeDetailModal">
      <a-form-model v-bind="layout">
        <a-form-model-item label="自动规则ID">{{ detailData.id || '-' }}</a-form-model-item>
        <a-form-model-item label="运行天数">{{ detailData.runDayCount }}</a-form-model-item>
        <a-form-model-item label="执行次数">{{ detailData.executeCount }}</a-form-model-item>
        <a-form-model-item label="企微通知人">{{ detailData.notifiedParty || '-' }}</a-form-model-item>
        <a-form-model-item label="创建人">{{ detailData.creator || '-' }}</a-form-model-item>
        <a-form-model-item label="创建时间">{{ detailData.ctime || '-' }}</a-form-model-item>
        <a-form-model-item label="更新时间">{{ detailData.mtime || '-' }}</a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-card>
</template>

<script>
import moment from 'moment';
import ruleModal from './components/ruleModal';

export default {
  components: {
    ruleModal,
  },
  data() {
    return {
      layout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      },
      form: {
        ruleTypeList: [],
        status: 'ENABLE',
      },
      loading: false,
      columns: [
        {
          align: 'center',
          title: '规则类型',
          dataIndex: 'ruleTypeName',
          customRender(text) {
            return text || '-';
          },
        },
        {
          align: 'center',
          title: '广告主',
          dataIndex: 'advertiserName',
          customRender(text) {
            return text || '-';
          },
        },
        {
          align: 'center',
          title: '广告平台',
          dataIndex: 'adPlatformTypeName',
          customRender(text) {
            return text || '-';
          },
        },
        {
          align: 'center',
          title: '适用层级',
          dataIndex: 'applyObjectType',
          customRender(text) {
            return text == 'ACCOUNT'
              ? '平台账套'
              : text == 'AD_PROJECT'
                ? '巨量项目'
                : text == 'AD_PROMOTION'
                  ? '广告'
                  : text == 'AD_MATERIAL'
                    ? '素材'
                    : '-';
          },
        },
        {
          align: 'center',
          title: '触发条件',
          dataIndex: 'conditionDesc',
          width: '450px',
          customRender: (text) => {

            text = text.replace(/\/n/g, '\n');
            return <pre style="white-space: pre-wrap; text-align: left;margin-bottom:0">{ text || '-' }</pre>;
          },
        },
        {
          align: 'center',
          title: '执行',
          dataIndex: 'actionArr',
          customRender: (text) => {
            let txt = '';
            text.map((item, index) => {
              txt += `${ index > 0 ? '、' : '' }${ item.actionName }`;
            });
            return <div style="white-space: pre-wrap;">{ txt }</div>;
          },
        },
        {
          align: 'center',
          title: '状态',
          dataIndex: 'ruleStatus',
          customRender(text) {
            return text == 'ENABLE' ? '启用' : '禁用';
          },
        },
        {
          align: 'center',
          title: '操作',
          scopedSlots: { customRender: 'action' },
        },
      ],
      pagination: {
        current: 1,
        pageSize: 50,
        total: 0,
      },
      dataList: [],
      openRulesModal: false,
      ruleType: undefined,
      activeRuleType: undefined,
      rulesTypeList: [],
      rulesTitle: '',
      rulesVisible: false,
      detailVisible: false,
      detailData: {},
      commanderIdList: [],
    };
  },
  created() {
    this.getAdRuleTypeList();
    this.getDataList();
    this.getTreeData();
  },
  methods: {
    //重置
    handleReset() {
      this.form = {
        ruleTypeList: [],
        status: 'ENABLE',
      };
    },
    //搜索
    handleSearch() {
      this.pagination.current = 1;
      this.getDataList();
    },
    //翻页
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    //获取列表数据
    getDataList() {
      console.log(this.form, '999999');
      this.loading = true;
      let _form = this.form.ruleTypeList.length != 0 ? this.form.ruleTypeList[0] : '';
      if (this.form.ruleTypeList.length > 1) {
        // this.form.ruleTypeList.forEach((item)=>{
        //   _form += `&ruleTypeList=${item}`
        // });
        for (let i = 1; i < this.form.ruleTypeList.length; i++) {
          _form += `&ruleTypeList=${ this.form.ruleTypeList[i] }`;
        }
      }
      console.log(_form, '66666');
      let params = {
        ruleTypeList: _form,
        page: this.pagination.current,
        size: this.pagination.pageSize,
        status: this.form.status,
        ruleId: this.form.ruleId || '',
        advertiserName: this.form.advertiserName || '',
      };
      this.$api.core.deliverManage.getAdRuleList(params).then((res) => {
        this.loading = false;
        if (res.code == 200) {
          this.dataList = res.data.list || [];
          this.pagination.total = res.data.total || 0;
        } else {
          this.dataList = [];
          this.pagination.total = 0;
          console.error(`获取列表失败${ res }`);
        }
      });
    },
    //获取详情
    getRuleDetail(id) {
      this.detailVisible = true;
      this.$api.core.deliverManage.getRuleDetail(id).then((res) => {
        if (res.code == 200) {
          let _data = res.data;
          _data.notifiedParty = _data.notifiedParty.toString();

          this.detailData = _data || {};
        } else {
          this.$message.error(`获取详情失败，${ res.message }`);
        }
      });
    },
    //关闭详情
    closeDetailModal() {
      this.detailVisible = false;
      this.detailData = {};
    },
    //删除
    deleteRule(id) {
      let that = this;
      this.$confirm({
        title: '操作确认',
        content: '是否要删除规则？',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          that.$api.core.deliverManage.deleteRule(id).then((res) => {
            if (res.code == 200) {
              that.$message.success('删除成功');
              that.getDataList();
            } else {
              that.$message.error('删除失败');
            }
          });
        },
      });
    },
    //操作
    updateStatus(record) {
      let data = {
        id: record.id,
        status: record.ruleStatus == 'ENABLE' ? 'DISABLE' : 'ENABLE',
      };
      this.$api.core.deliverManage.updateStatus(data).then((res) => {
        if (res.code == 200) {
          this.$message.success('操作成功');
          this.getDataList();
        } else {
          this.$message.error(`操作失败，${ res.message }`);
        }
      });
    },
    //获取规则类型数据
    getAdRuleTypeList() {
      this.$api.core.deliverManage.getAdRuleTypeList().then((res) => {
        if (res.code == 200) {
          this.rulesTypeList = res.data;
        } else {
          this.$message.error(`获取规则数据失败，${ res.message }`);
        }
      });
    },
    // 打开规则管理类型
    openRulesListModal() {
      this.openRulesModal = true;
    },
    //关闭规则管理类型
    closeRulesListModal() {
      this.ruleType = undefined;
      this.activeRuleType = undefined;
      this.openRulesModal = false;
    },
    //打开新建规则
    onCLickType(item) {
      let label = item.msg;
      let title = label.indexOf('新建') == 0 ? label : `新建${ label }`;

      this.rulesTitle = title;
      this.rulesVisible = true;
      this.activeRuleType = item.code;
    },
    //关闭规则弹窗
    handleCancel() {
      this.rulesTitle = '';
      this.rulesVisible = false;
    },
    //提交规则弹窗
    handleSubmit() {
      this.closeRulesListModal();
      this.getDataList();
    },
    // 获取节点数据
    getTreeData() {
      this.$api.workOrder.getTreeData().then((res) => {
        if (res.code == 200) {
          let arr = this.getAllChildren(res.data.children || []);
          this.commanderIdList = arr;
        } else {
          this.$message.error(`获取联系人数据失败,${ res.message }`);
        }
      });
    },
    getAllChildren(data) {
      let res = [];
      data?.forEach((item) => {
        res = [...res, ...item.employees];
        if (item.children.length) {
          res = [...res, ...this.getAllChildren(item.children)];
        }
      });

      return res;
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
  padding-left: 0;
  padding-right: 0;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mt-20 {
  margin-top: 20px;
}
</style>
